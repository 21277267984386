import jQuery from 'jQuery'

/* eslint-disable */
jQuery(function($){ // use jQuery code inside this to avoid '$ is not defined' error

	$('.posts--loadmore').click(function(){

    if( $(this).hasClass('posts--loadmore--event') ){
      //let post_type = 'event';
      let thisCalendarType = '';
      let thisNoResultsText = '';
      let thisInitialLoad = '';
      let thisIsInitialLoad = '';
      let thisDateRange = [];
      let thisSortBy = '';
      let signatureEventsQuery = '';
      let thisVenues = [];
      let thisPillars = [];
      let thisPostPerPage = '';
      let thisBlockId = '#' + $(this).get(0).dataset.blockid;

      thisCalendarType = $(this).get(0).dataset.calendartype;
      thisNoResultsText = $(this).get(0).dataset.noresultstext;
      thisInitialLoad = $(this).get(0).dataset.initialload;
      thisIsInitialLoad = $(this).get(0).dataset.isinitialload;
      thisDateRange = $(this).get(0).dataset.daterange.split(',');
      thisSortBy = $(this).get(0).dataset.sortby;
      signatureEventsQuery = $(this).get(0).dataset.signatureeventsquery;
      thisVenues = $(this).get(0).dataset.venues.split(',');
      thisPillars = $(this).get(0).dataset.pillars.split(',');

      thisDateRange = thisDateRange.filter(Boolean);
      thisVenues = thisVenues.filter(Boolean);
      thisPillars = thisPillars.filter(Boolean);

      if( thisVenues.length == 0 ){
        thisVenues.push('0');
      }

      let thisQuery = {};

      if( thisIsInitialLoad == true || thisIsInitialLoad == 'true' ){
        thisPostPerPage = thisInitialLoad;
      }else{
        thisPostPerPage = '-1'
      }

      // General and Sports calendars are nearly identical, so they share nearly the same query
      if( thisCalendarType != 'booking' ){
        thisQuery.post_type = 'event';
        thisQuery.posts_per_page = thisPostPerPage;
        thisQuery.orderby = 'meta_value';
        thisQuery.order = thisSortBy;
        thisQuery.meta_query = [];
        // date range
        thisQuery.meta_query[0] = {};
        thisQuery.meta_query[0].key = 'dates_$_date';
        thisQuery.meta_query[0].value = thisDateRange;
        thisQuery.meta_query[0].compare = 'BETWEEN';
        thisQuery.meta_query[0].type = 'DATE';
        thisQuery.meta_query[1] = {};
        // venue
        thisQuery.meta_query[1].relation = 'OR';
        if( thisVenues[0] == '0' ){
          thisQuery.meta_query[1] = {};
          thisQuery.meta_query[1].key = 'venue';
          thisQuery.meta_query[1].value = thisVenues;
          thisQuery.meta_query[1].compare = 'IN';
        }else{
          for( let i = 0; i < thisVenues.length; i++ ){
            thisQuery.meta_query[1][i] = {};
            thisQuery.meta_query[1][i].key = 'venue';
            thisQuery.meta_query[1][i].value = thisVenues[i];
            thisQuery.meta_query[1][i].compare = 'LIKE';
          }
        }
        // event type
        if(signatureEventsQuery == '0'){
          thisQuery.meta_query[2] = {};
          thisQuery.meta_query[2].key = 'event_type';
          thisQuery.meta_query[2].value = ['signature', 'booking'];
          thisQuery.meta_query[2].compare = 'NOT IN'
        }else{
          thisQuery.meta_query[2] = {};
          thisQuery.meta_query[2].key = 'event_type';
          thisQuery.meta_query[2].value = ['booking'];
          thisQuery.meta_query[2].compare = 'NOT IN'
        }
        thisQuery.tax_query = {};
        thisQuery.tax_query.relation = 'OR';
        thisQuery.tax_query[0] = {};
        // pillar or sport
        if( thisCalendarType == 'general' ){
          thisQuery.tax_query[0].taxonomy = 'pillar';
        }else if( thisCalendarType == 'sports' ){
          thisQuery.tax_query[0].taxonomy = 'sport';
        }
        thisQuery.tax_query[0].field = 'term_id';
        thisQuery.tax_query[0].terms = thisPillars;

      } else {// booking calendar
        thisQuery.post_type = 'event';
        thisQuery.posts_per_page = thisPostPerPage;
        thisQuery.orderby = 'meta_value';
        thisQuery.order = thisSortBy;
        thisQuery.meta_query = [];

        // starts from date
        thisQuery.meta_query[0] = {};
        thisQuery.meta_query[0].key = 'start_date';
        thisQuery.meta_query[0].value = thisDateRange[1];
        thisQuery.meta_query[0].compare = '<='; // The start date begins on or before the date range
        thisQuery.meta_query[0].type = 'DATE';

        // ends on date
        thisQuery.meta_query[1] = {};
        thisQuery.meta_query[1].key = 'end_date';
        thisQuery.meta_query[1].value = thisDateRange[0];
        thisQuery.meta_query[1].compare = '>='; // The end date ends on or after the date range
        thisQuery.meta_query[1].type = 'DATE';

        // venue
        //thisQuery.meta_query[2] = {};
        //thisQuery.meta_query[2].key = 'venue';
        //thisQuery.meta_query[2].value = thisVenues;
        //thisQuery.meta_query[2].compare = 'IN';
        // venue
        thisQuery.meta_query[2] = {};
        if( thisVenues[0] == '0' ){
          thisQuery.meta_query[2].key = 'venue';
          thisQuery.meta_query[2].value = thisVenues;
          thisQuery.meta_query[2].compare = 'IN';
        }else{
          thisQuery.meta_query[2].relation = 'OR';
          for( let i = 0; i < thisVenues.length; i++ ){
            thisQuery.meta_query[2][i] = {};
            thisQuery.meta_query[2][i].key = 'venue';
            thisQuery.meta_query[2][i].value = thisVenues[i];
            thisQuery.meta_query[2][i].compare = 'LIKE';
          }
        }

        if(signatureEventsQuery == '0'){
          thisQuery.meta_query[3] = {};
          thisQuery.meta_query[3].key = 'event_type';
          thisQuery.meta_query[3].value = ['signature', 'general'];
          thisQuery.meta_query[3].compare = 'NOT IN'
        }else{
          thisQuery.meta_query[3] = {};
          thisQuery.meta_query[3].key = 'event_type';
          thisQuery.meta_query[3].value = ['general'];
          thisQuery.meta_query[3].compare = 'NOT IN'
        }

        thisQuery.tax_query = {};
        thisQuery.tax_query.relation = 'OR';
        thisQuery.tax_query[0] = {};
        thisQuery.tax_query[0].taxonomy = 'schedule_type';
        thisQuery.tax_query[0].field = 'term_id';
        thisQuery.tax_query[0].terms = thisPillars;

      }

      //console.log(thisQuery);

      var data = {
        'action': 'loadmore',
        'noResultsText': thisNoResultsText,
        'query': JSON.stringify(thisQuery), // that's how we get params from wp_localize_script() function
        'page' : '0', //eslint disable-line
        'calendarType' : thisCalendarType,
      };

      $.post({ // you can also use $.post here
        url : '/wp-admin/admin-ajax.php', // AJAX handler
        data : data,
        type : 'POST',
        success : function( data ){
          if( data ) {
            //console.log(data);
            const p1 = Promise.resolve($(thisBlockId + ' .upcoming-events__events').html(data));
            p1.then(function(){
                $(thisBlockId + ' .upcoming-events__inner[data-viewmode="month"] .hiddenViewModeMonthButton').click();
            }).then(function(){
              setTimeout(function(){
                $(thisBlockId + ' .readEventsButton').click();
              }, 1000);
            }); // insert new posts
          } else {
            console.log('events query failed');
            $(thisBlockId + ' .upcoming-events__events').html(data); // insert new posts
            setTimeout(function(){
              $(thisBlockId + ' .readEventsButton').click();
            }, 1000);
          }
        }
      });
    }

	});
});
